<template>
    <InvoiceData/>
</template>
<script>
import InvoiceData from '../components/invoices/InvoiceData';

export default {
  components: {
    InvoiceData,
  },
};
</script>
