import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export function formatDate(val, toFormat = 'D MMM YYYY HH:mm', fromFormat = 'YYYY-MM-DD HH:mm:ss'/* , toUTC = false */) {
  /* if (toUTC) {
    return val ? dayjs(String(val), fromFormat)
      .utc()
      .format(toFormat) : null;
  } */
  // Always store and show in UTC
  return val ? dayjs.utc(String(val), fromFormat)
    .format(toFormat) : null;
}

/* export function formatDate(val, toFormat = 'D MMM YYYY HH:mm',
  fromFormat = 'YYYY-MM-DD HH:mm:ss', toUTC = false) {
  if (toUTC) {
    return val ? dayjs(String(val), fromFormat)
      .utc()
      .format(toFormat) : null;
  }
  return val ? dayjs.utc(String(val), fromFormat)
    .local()
    .format(toFormat) : null;
} */
