<template>
    <div>
        <div class="col-12 placeholder">
            <div class="placeholder__avatar pulsate"></div>
            <div class="placeholder__right pulsate">
                <div class="placeholder__text placeholder__text--title"></div>
                <div class="placeholder__text"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>
