<template>
  <div class="row justify-content-center">
    <transition name="fade" mode="out-in">
      <AppPlaceholder v-if="!invoice" key="no-invoice" />
      <div v-else class="invoice-box" key="invoice">
        <table cellpadding="0" cellspacing="0">
          <tr class="top">
            <td colspan="5">
              <table>
                <tr>
                  <td class="title">
                    <img src="@/assets/img/logo.png" alt="" />
                  </td>
                  <td>
                    <h1 class="text-muted"><strong>INVOICE</strong></h1>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <span v-if="invoice.issued_at">
                              Date:
                              {{ invoice.issued_at | date("DD MMMM YYYY") }}<br />
                            </span>
                            <span v-if="invoice.due_at">
                              Due at:
                              {{ invoice.due_at | date("DD MMMM YYYY") }}<br />
                            </span>
                            <button
                              class="
                                            btn
                                            btn-sm
                                            btn-link
                                            text-muted
                                            d-print-none
                                            invoice-box__btn"
                              @click="editInvoiceDates"
                            >
                              <i class="material-icons md-18">edit</i>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Invoice no.:
                            <strong>{{ invoice.number }}</strong>
                            <button
                              class="
                                            btn
                                            btn-sm
                                            btn-link
                                            text-muted
                                            d-print-none
                                            invoice-box__btn"
                              @click="editInvoiceNo"
                            >
                              <i class="material-icons md-18">edit</i>
                            </button>
                            <br />
                            VAT no.: {{ issuing_company.vat_no }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr class="information">
            <td colspan="5">
              <table>
                <tr>
                  <td v-if="invoice.client_company">
                    Bill to:<br />
                    <strong>{{ invoice.client_company.name }}</strong
                    ><br />
                    {{ invoice.client_address }}<br />
                    {{ invoice.client_city }}, {{ invoice.client_postal_code }}<br />
                    <span v-if="invoice.client_country">
                      {{ invoice.client_country.name }} </span
                    ><br />
                    {{ invoice.client_company.email }}<br />
                    <span v-if="invoice.client_contact_user">
                      Attention: {{ invoice.client_contact_user.name }}
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="text-right" v-if="invoice.reference_no">
              Ref no. {{ invoice.reference_no }}
            </td>
          </tr>

          <tr class="heading">
            <td colspan="4">Description</td>
            <td>Amount</td>
          </tr>

          <tr class="item last" v-for="line in invoice.invoice_lines" :key="line.id">
            <td v-html="line.description" style="white-space:pre-line" colspan="4"></td>
            <td>
              <span v-if="invoice.currency && lineTotal(line)">
                {{ lineTotal(line) | currency(2, invoice.currency.code) }}
              </span>
              <button
                class="
                                btn
                                btn-sm
                                btn-link
                                text-muted
                                d-print-none
                                invoice-box__btn"
                @click="editOrderLine(line.id)"
              >
                <i class="material-icons md-18">edit</i>
              </button>
            </td>
          </tr>

          <tr class="total">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <strong v-if="invoice.currency">
                Total: {{ totalAmount | currency(2, invoice.currency.code) }}
              </strong>
            </td>
          </tr>

          <tr class="heading">
            <td colspan="5" class="text-left">
              Notes:
              <button
                class="
                                btn
                                btn-sm
                                btn-link
                                text-muted
                                d-print-none
                                invoice-box__btn"
                @click="editNotes"
              >
                <i class="material-icons md-18">edit</i>
              </button>
            </td>
          </tr>
          <tr>
            <td v-html="invoice.notes" style="white-space:pre-line"></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr class="footer">
            <td colspan="5">
              <table>
                <tr>
                  <td v-html="invoice.footer_note" class="pt-3 text-left">
                  </td>
                  <td> <button class="
                            btn
                            btn-sm
                            btn-link
                            text-muted
                            d-print-none
                            invoice-box__btn"
                      @click="editFooterNote">
                      <i class="material-icons md-18">edit</i>
                    </button></td>
                </tr>
                <tr>
                  <td class="pt-3">
                    <strong>Bankers:</strong><br />
                    {{ bankAccount.bank_name }},
                    {{ bankAccount.bank_postal_code }}
                    {{ bankAccount.bank_city }}, {{ bankAccount.bank_country }}<br />
                    Swift: {{ bankAccount.swift }}<br />
                    <span v-if="invoice.currency">
                      {{ invoice.currency.code }}
                    </span>
                    acc. no.
                    {{ bankAccount.account_no }}
                    <br />
                    IBAN: {{ bankAccount.iban }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tfoot class="text-center">
                  <tr>
                    <td class="pt-5">
                      <small>
                        <strong>{{ issuing_company.name }}</strong>,
                        {{ issuing_company.address }}, {{ issuing_company.city }},
                        {{ issuing_company.postal_code }}, {{ issuing_company.country }}<br />
                        Tel: {{ issuing_company.phone }}, Fax: {{ issuing_company.fax }}, Email:
                        {{ issuing_company.email }}</small
                      >
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppPlaceholder from '../form/AppPlaceholder';
import formatCurrency from '../../filters/currency.filter';
import { formatDate } from '../../filters/date.filter';

export default {
  components: {
    AppPlaceholder,
  },
  filters: {
    currency: formatCurrency,
    date: formatDate,
  },
  data() {
    return {
      issuing_company: {
        name: 'Petrochem Energy SA',
        address: 'Poststrasse 14',
        city: 'Zug',
        postal_code: '6301',
        country: 'Switzerland',
        email: 'ops@petrochemenergy.com',
        phone: '+41 41 727 8155',
        fax: '+41 41 727 8149',
        vat_no: 'CHE-114.341.413 MWST',
        bank_accounts: {
          EUR: {
            account_no: '1264097-62',
            iban: 'CH74 0483 5126 4097 6200 0',
            swift: 'CRESCHZZ80A',
            bank_name: 'Credit Suisse',
            bank_postal_code: '6301',
            bank_city: 'Zug',
            bank_country: 'Switzerland',
          },
          USD: {
            account_no: '1264097-62-1',
            iban: 'CH47 0483 5126 4097 6200 1',
            swift: 'CRESCHZZ80A',
            bank_name: 'Credit Suisse',
            bank_postal_code: '6301',
            bank_city: 'Zug',
            bank_country: 'Switzerland',
          },
          CHF: {
            account_no: '1264097-61',
            iban: 'CH11 0483 5126 4097 6100 0',
            swift: 'CRESCHZZ63A',
            bank_name: 'Credit Suisse',
            bank_postal_code: '6301',
            bank_city: 'Zug',
            bank_country: 'Switzerland',
          },
          AED: {
            account_no: '1264097-62-6',
            iban: 'CH09 0483 5126 4097 6200 6',
            swift: 'CRESCHZZ80A',
            bank_name: 'Credit Suisse',
            bank_postal_code: '6301',
            bank_city: 'Zug',
            bank_country: 'Switzerland',
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      invoice: 'invoices/invoice',
      totalAmount: 'invoices/totalAmount',
    }),
    bankAccount() {
      return this.invoice.currency_code
        && this.issuing_company.bank_accounts.hasOwnProperty(this.invoice.currency_code)
        ? this.issuing_company.bank_accounts[this.invoice.currency_code]
        : this.issuing_company.bank_accounts.EUR;
    },
  },
  mounted() {
    this.initInvoice();
  },
  watch: {
    '$route.params.id'() {
      this.initInvoice();
    },
  },
  methods: {
    editFooterNote() {
      this.$store.commit('invoices/isFooterNoteModalOpen', true);
    },
    editInvoiceNo() {
      this.$store.commit('invoices/isInvoiceNoModalOpen', true);
    },
    editInvoiceDates() {
      this.$store.commit('invoices/isDatesModalOpen', true);
    },
    editNotes() {
      this.$store.commit('invoices/isNotesModalOpen', true);
    },
    formatDescription(description) {
      return description.replace(/(\n)+/g, '\n');
    },
    editOrderLine(lineId) {
      this.$store.dispatch('invoices/openLineModal', lineId);
    },
    initInvoice() {
      this.$store.dispatch('invoices/getInvoice', this.$route.params.id);
    },
    print() {
      window.print();
    },
    lineTotal(line) {
      return (line.quantity ? line.quantity : 1) * line.price;
    },
  },
};
</script>
